export const carForYouPageEvents = {
    "carsForYouEntry": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "cars_for_you_entry"
    },
    "carsForYouLeftNudge": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "cars_for_you_bottom_left_nudge_shown"
    },
    "carsForYouFloatingCta": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "cars_for_you_floating_cta_shown"
    },
    "carsForYouBottomLeft": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "cars_for_you_bottom_left_nudge"
    },
    "carsForYouFloatingCtaClick": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "cars_for_you_floating_cta"
    },
    "aiSearchBoxClick": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_box_click"
    },
    "aiSearchEnter": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_enter"
    },
    "aiSearchExample": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_example"
    },
    "aiSearchPageVisited": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_page_visited"
    },
    "aiSearchCarClick": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_car_clicked"
    },
    "aiSearchImpression": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_impression"
    },
    "aiAddToWishlist": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "add_to_wishlist"
    },
    "aiSearchSeeAll": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_see_all"
    },
    "aiSearchBack": {
        "event": "custom_event",
        "eventCategory": "cars_for_you",
        "eventAction": "ai_search_back"
    }
};
