import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AiBotIcon from "./component";
import { updateOpenAICarFinderModal } from "../../ae.configuration/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateOpenAICarFinderModalConnect: updateOpenAICarFinderModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AiBotIcon);
