import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { COOKIE_CONSTANTS, NUMBER } from "../../../constants/app-constants";
import styles from "./styles.css";
import parseCookie from "../../../utils/helpers/parse-cookie";
import saveCookie from "../../../utils/helpers/save-cookie";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { BANNER_SALE_URL } from "../value-proposition-card-v2/constant";
import { carForYouPageEvents } from "../../../tracking/msite/car-for-you-page-events";

const AdditionalHeight = NUMBER.SIXTY_EIGHT;

const AIBotIcon = ({
    bottom = NUMBER.EIGHTY_FOUR,
    isBottomNavVisible = false,
    isAnotherCTAVisible = false,
    isShowCheckLoanBudgetCta = false,
    route,
    updateOpenAICarFinderModalConnect = () => { }
}) => {
    const [expandedIcon, setExpandedIcon] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const hasAiIconExpanded = parseCookie(COOKIE_CONSTANTS.AI_EXPANDED_ICON);
    useEffect(() => {
        trackPlatformCustomEventsAEV2(carForYouPageEvents.carsForYouFloatingCta);
    }, []);
    const isScrolledSecondFold = () => {
        return window.scrollY >= NUMBER.FOUR_HUNDRED;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleScroll = () => {
        if (!hasAiIconExpanded && isScrolledSecondFold() && !expandedIcon) {
            setExpandedIcon(true);
            window.setTimeout(() => {
                saveCookie(COOKIE_CONSTANTS.AI_EXPANDED_ICON, true);
                setExpandedIcon(false);
            }, NUMBER.TWO_THOUSAND);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);
    const calculateBottom = () => {
        let updatedBottom = bottom;
        if (isAnotherCTAVisible) updatedBottom += AdditionalHeight;
        if (isBottomNavVisible) updatedBottom += AdditionalHeight;
        if (isShowCheckLoanBudgetCta) updatedBottom += AdditionalHeight;
        return updatedBottom;
    };

    const navigateToAiCarFinder = async () => {
        setShowLoader(true);
        await yieldToMainThread();
        updateOpenAICarFinderModalConnect("floating_cta");
        await yieldToMainThread();
        window.setTimeout(() => {
            setShowLoader(false);
        }, NUMBER.FIVE_HUNDRED);
        trackPlatformCustomEventsAEV2(carForYouPageEvents.carsForYouFloatingCtaClick, { eventLabel: route?.name });
    };
    return (
        <div className="aiBot" styleName={`styles.aiBot styles.mobileDefaultArrow ${expandedIcon ? "styles.expand" : ""}`} onClick={navigateToAiCarFinder} style={{ right: NUMBER.TWENTY, bottom: (calculateBottom() || NUMBER.SEVENTY) }}>
            {
                showLoader ?
                    <i className="spinner" />
                    :
                    <img src={`${BANNER_SALE_URL}ai-finder-icon-2.webp`} alt="Ai Bot" width={NUMBER.TWENTY_EIGHT} height={NUMBER.TWENTY_EIGHT} />
            }
            {expandedIcon && <span styleName="styles.text">Ai Car Finder</span>}
        </div>
    );
};

AIBotIcon.propTypes = {
    hide: PropTypes.bool,
    bottom: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
    route: PropTypes.object,
    updateOpenAICarFinderModalConnect: PropTypes.func,
    routeName: PropTypes.string,
    isBottomNavVisible: PropTypes.bool,
    isAnotherCTAVisible: PropTypes.bool,
    isShowCheckLoanBudgetCta: PropTypes.bool
};

export default AIBotIcon;
